<template>
    <div>
        <el-divider></el-divider>
        <el-row>
            {{ $t("lang.日期") }}：<el-date-picker v-model="searchTime" @change="getList"></el-date-picker>
        </el-row>
        <el-col :span="12">
            <el-table ref="refElTable" :data="tableData" v-loading="tableLoading" highlight-current-row
                @current-change="loadDetail">
                <el-table-column :label="$t('lang.序列号')" prop="serialNumber" width="100"></el-table-column>
                <el-table-column :label="$t('lang.孔号')" prop="shiYanBianHao" width="60" align="right"></el-table-column>
                <el-table-column :label="$t('lang.试验时间')" prop="shiYanShiJian" width="150"></el-table-column>
                <el-table-column :label="$t('lang.截面积')" prop="jieMianJi" width="80" align="right"
                    :formatter="v => { return v.jieMianJi + 'cm²'; }"></el-table-column>
                <el-table-column :label="$t('lang.位移点长度')" prop="weiYiDian" width="100" align="right"
                    :formatter="v => { return v.weiYiDian + 'mm'; }"></el-table-column>
                <el-table-column :label="$t('lang.起始深度')" prop="qiShiShenDu" width="100" align="right"
                    :formatter="v => { return v.qiShiShenDu + 'm'; }"></el-table-column>
                <el-table-column :label="$t('lang.操作')" width="190" align="center">
                    <template #default="scope">
                        <el-button-group>
                            <el-button type="primary" size="mini" @click="print(scope.row)">{{ $t("lang.打印") }}</el-button>
                            <el-button type="success" size="mini" @click="exportTxt(scope.row)">{{ $t("lang.导出")
                            }}</el-button>
                            <el-button type="danger" size="mini" @click="del(scope.row)">{{ $t("lang.删除") }}</el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="getList" background :total="total"
                :current-page.sync="currentPage"></el-pagination>
        </el-col>
        <el-col :span="12">
            <el-tabs stretch value="0">
                <el-tab-pane :label="$t('lang.图形')" name="0">
                    <div id="jlct0Chart"></div>
                </el-tab-pane>
                <el-tab-pane :label="$t('lang.详细信息')" name="1">
                    <el-table :data="detailData" max-height="600" border>
                        <el-table-column :label="$t('lang.深度')" prop="shenDu" align="right"
                            :formatter="v => { return v.shenDu + 'm'; }"></el-table-column>
                        <el-table-column :label="$t('lang.阻力')" prop="zuLi" align="right"
                            :formatter="v => { return v.zuLi + 'N'; }"></el-table-column>
                        <el-table-column :label="$t('lang.锥尖阻力')" prop="zhuiJianZuLi" align="right"
                            :formatter="v => { return v.zhuiJianZuLi + 'kPa'; }"></el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </el-col>
    </div>
</template>

<script>
import HighCharts from "highcharts";

export default {
    data() {
        return {
            currentPage: 1,
            total: 0,
            tableLoading: false,
            searchTime: null,
            tableData: null,
            detailData: [],
        };
    },
    methods: {
        getList() {
            let that = this;
            that.tableLoading = true;
            that.axios
                .post("JLCT/GetMoShi0List", {
                    currentPage: that.currentPage,
                    total: that.total,
                    onlyDateTime: that.searchTime,
                })
                .then(function (response) {
                    that.tableLoading = false;
                    that.total = response.data.data.total;
                    that.tableData = response.data.data.data;
                    if (that.tableData.length > 0) that.$refs.refElTable.setCurrentRow(that.tableData[0]);
                });
        },
        del(row) {
            let that = this;
            that
                .$confirm(that.$t("lang.确定要删除吗"))
                .then(function () {
                    that.axios.post("JLCT/DelJLCT0", row).then(() => {
                        that.getList();
                    });
                })
                .catch(function () { });
        },
        print(row) {
            open(process.env.VUE_APP_TurnUrl + "JLCT01/print?id=" + row.id + "&&lang=" + window.localStorage.getItem("lang"));
        },
        exportTxt(row) {
            open(process.env.VUE_APP_TurnUrl + "JLCT01/Txt?id=" + row.id);
        },
        loadDetail(row) {
            let that = this;
            if (row.shiYanShuJu) {
                var arr = row.shiYanShuJu.split(",");
                var qiShiShenDu = row.qiShiShenDu;
                var arrData = [{ name: that.$t("lang.深度压强"), data: [] }];
                var arrData1 = [];
                for (var i = 0; i < arr.length - 1; i++) {
                    var shenDu = parseFloat((-(i * row.weiYiDianChangDu) / 1000.0).toFixed(3));
                    var zuLi = parseFloat(arr[i]);
                    var zhuiJianZuLi = parseFloat(((zuLi * 10) / row.jieMianJi).toFixed(3));
                    arrData[0].data.push([zhuiJianZuLi, shenDu]);
                    //图形中显示下降深度。列表中显示真实深度
                    shenDu = shenDu - qiShiShenDu;
                    arrData1.push({
                        shenDu: shenDu,
                        zuLi: zuLi,
                        zhuiJianZuLi: zhuiJianZuLi,
                    });
                }
                that.loadChart(arrData, row.shiYanBianHao, qiShiShenDu);
                that.detailData = arrData1;
                //$("#tbJLCT01Detail").bootstrapTable("load", arrData1);
            }
        },
        loadChart(data, shiYanBianHao, qiShiShenDu) {
            let that = this;

            HighCharts.chart("jlct0Chart", {
                lang: {
                    viewFullscreen: that.$t("lang.全屏"),
                    printChart: that.$t("lang.打印图表"),
                    downloadJPEG: that.$t("lang.导出JPEG"),
                    downloadPDF: that.$t("lang.导出PDF"),
                    downloadPNG: that.$t("lang.导出PNG"),
                    downloadSVG: that.$t("lang.导出SVG"),
                },
                title: {
                    text: that.$t("lang.孔号") + ":" + shiYanBianHao + " , " + that.$t("lang.起始深度") + ":" + qiShiShenDu + " m",
                },
                tooltip: {
                    formatter: function () {
                        return that.$t("lang.压强") + "：<b>" + this.x + "</b>kPa<br />" + that.$t("lang.深度") + "：<b>" + this.y + "</b>m";
                    },
                },
                xAxis: {
                    title: {
                        text: that.$t("lang.压强") + "(kPa)",
                    },
                    opposite: true,
                },
                yAxis: {
                    title: {
                        text: that.$t("lang.深度") + "(m)",
                    },
                },
                series: data,
                credits: {
                    enabled: false,
                },
            });

        },
    },
    mounted() {
        let that = this;
        that.getList();
    },
};
</script>

<style></style>